define("discourse/plugins/discourse-templates/components/template-item", ["exports", "@ember/component", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "../lib/apply-template"], function (_exports, _component, _object, _ajax, _ajaxError, _applyTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_obj = {
    classNames: ["templates-template-item"],

    apply() {
      var _this$onInsertTemplat;

      const template = (0, _applyTemplate.prepareTemplate)(this.template.title, this.template.content, this.model); // run parametrized action to insert the template

      (_this$onInsertTemplat = this.onInsertTemplate) === null || _this$onInsertTemplat === void 0 ? void 0 : _this$onInsertTemplat.call(this, template);
      (0, _ajax.ajax)("/discourse_templates/".concat(this.template.id, "/use"), {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    }

  }, (_applyDecoratedDescriptor(_obj, "apply", [_object.action], Object.getOwnPropertyDescriptor(_obj, "apply"), _obj)), _obj));

  _exports.default = _default;
});
define("discourse/plugins/discourse-templates/components/templates-filterable-list", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/mixins/textarea-text-manipulation", "discourse-common/utils/decorators", "select-kit/components/tag-drop", "../lib/apply-template"], function (_exports, _component, _object, _runloop, _ajax, _ajaxError, _textareaTextManipulation, _decorators, _tagDrop, _applyTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend(_textareaTextManipulation.default, (_dec = (0, _decorators.default)("replies", "selectedTag", "listFilter"), (_obj = {
    classNames: ["templates-filterable-list"],

    init() {
      this._super(...arguments);

      this.setProperties({
        loadingReplies: false,
        listFilter: "",
        replies: [],
        selectedTag: _tagDrop.ALL_TAGS_ID,
        availableTags: [],
        _textarea: null,
        ready: false
      });
    },

    didInsertElement() {
      this._load();
    },

    filteredReplies(replies, selectedTag, listFilter) {
      const filterTitle = listFilter.toLowerCase();
      return replies.map(template => {
        /* Give a relevant score to each template. */
        template.score = 0;

        if (template.title.toLowerCase().includes(filterTitle)) {
          template.score += 2;
        } else if (template.content.toLowerCase().includes(filterTitle)) {
          template.score += 1;
        }

        return template;
      }) // Filter irrelevant replies.
      .filter(template => template.score !== 0) // Filter only replies tagged with the selected tag.
      .filter(template => {
        if (selectedTag === _tagDrop.ALL_TAGS_ID) {
          return true;
        }

        if (selectedTag === _tagDrop.NO_TAG_ID && template.tags.length === 0) {
          return true;
        }

        return template.tags.includes(selectedTag);
      }).sort((a, b) => {
        /* Sort replies by relevance and title. */
        if (a.score !== b.score) {
          return a.score > b.score ? -1 : 1;
          /* descending */
        } else if (a.title !== b.title) {
          return a.title < b.title ? -1 : 1;
          /* ascending */
        }

        return 0;
      });
    },

    changeSelectedTag(tagId) {
      this.set("selectedTag", tagId);
    },

    insertTemplate(template) {
      var _this$onBeforeInsertT, _this$onAfterInsertTe;

      this._textarea = this.textarea;
      (_this$onBeforeInsertT = this.onBeforeInsertTemplate) === null || _this$onBeforeInsertT === void 0 ? void 0 : _this$onBeforeInsertT.call(this);

      if (this._textarea) {
        this._addBlock(this.getSelected(), template.templateContent);
      } else {
        (0, _applyTemplate.insertTemplateIntoComposer)(this, template);
      }

      (_this$onAfterInsertTe = this.onAfterInsertTemplate) === null || _this$onAfterInsertTe === void 0 ? void 0 : _this$onAfterInsertTe.call(this);
    },

    _load() {
      (0, _ajax.ajax)("/discourse_templates").then(results => {
        this.setProperties({
          ready: true,
          replies: results.templates,
          availableTags: this.siteSettings.tagging_enabled ? Object.values(results.templates.reduce((availableTags, template) => {
            template.tags.forEach(tag => {
              if (availableTags[tag]) {
                availableTags[tag].count += 1;
              } else {
                availableTags[tag] = {
                  id: tag,
                  name: tag,
                  count: 1
                };
              }
            });
            return availableTags;
          }, {})) : []
        });
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("loadingReplies", false);
        (0, _runloop.schedule)("afterRender", () => {
          var _document$querySelect;

          return (_document$querySelect = document.querySelector(".templates-filter")) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.focus();
        });
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "filteredReplies", [_dec], Object.getOwnPropertyDescriptor(_obj, "filteredReplies"), _obj), _applyDecoratedDescriptor(_obj, "changeSelectedTag", [_object.action], Object.getOwnPropertyDescriptor(_obj, "changeSelectedTag"), _obj), _applyDecoratedDescriptor(_obj, "insertTemplate", [_object.action], Object.getOwnPropertyDescriptor(_obj, "insertTemplate"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-templates/components/templates-form", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({});

  _exports.default = _default;
});
define("discourse/plugins/discourse-templates/components/templates-tag-drop", ["exports", "discourse-common/utils/decorators", "select-kit/components/tag-drop"], function (_exports, _decorators, _tagDrop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _tagDrop.default.extend((_dec = (0, _decorators.default)("availableTags.[]"), (_obj = {
    topTags(availableTags) {
      // sort tags descending by count and ascending by name
      return (availableTags || []).sort((a, b) => {
        if (a.count !== b.count) {
          return b.count - a.count;
        } // descending


        if (a.name !== b.name) {
          return a.name < b.name ? -1 : 1;
        } // ascending


        return 0;
      });
    },

    search(filter) {
      return (this.content || []).map(tag => {
        if (tag.id && tag.name) {
          return tag;
        }

        return this.defaultItem(tag, tag);
      }).filter(tag => {
        if (filter == null) {
          return true;
        }

        const tagFilter = filter.toLowerCase();
        return tag.id.toLowerCase().includes(tagFilter) || tag.name.toLowerCase().includes(tagFilter);
      });
    },

    actions: {
      onChange(tagId, tag) {
        // overrides the action onChange of the parent with the value received in
        // the property onChange in the handlebars template
        this.onChange && this.onChange(tagId, tag);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "topTags", [_dec], Object.getOwnPropertyDescriptor(_obj, "topTags"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-templates/connectors/above-modal-header/discourse-templates-modal-hijacker", ["exports", "@ember/object", "discourse-common/lib/get-owner"], function (_exports, _object, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = (_obj = {
    setupComponent(args, component) {
      component.setProperties({
        active: false,
        model: (0, _getOwner.getOwner)(this).lookup("controller:composer").model,
        displayMap: new WeakMap()
      });
      this.appEvents.on("discourse-templates:hijack-modal", this, "show");
      this.appEvents.on("discourse-templates:restore-modal", this, "hide");
      this.appEvents.on("modal:body-dismissed", this, "hide");
    },

    teardownComponent() {
      this.appEvents.off("discourse-templates:hijack-modal", this, "show");
      this.appEvents.off("discourse-templates:restore-modal", this, "hide");
      this.appEvents.off("modal:body-dismissed", this, "hide");
    },

    show(_ref) {
      let {
        textarea
      } = _ref;
      const container = document.querySelector(".modal-inner-container");

      if (textarea) {
        this.set("active", true);
        this.set("textarea", textarea);
        Array.from(container.children).forEach(element => {
          if (element === this.element) {
            return;
          }

          this.displayMap.set(element, element.style.display);
          element.style.display = "none";
        });
      }
    },

    hide() {
      this.set("active", false);
      const container = document.querySelector(".modal-inner-container");
      Array.from(container.children).forEach(element => {
        const oldDisplay = this.displayMap.get(element);

        if (oldDisplay != null) {
          this.displayMap.delete(element);
          element.style.display = oldDisplay;
        }
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "show", [_object.action], Object.getOwnPropertyDescriptor(_obj, "show"), _obj), _applyDecoratedDescriptor(_obj, "hide", [_object.action], Object.getOwnPropertyDescriptor(_obj, "hide"), _obj)), _obj);

  _exports.default = _default;
});
define("discourse/plugins/discourse-templates/connectors/editor-preview/discourse-templates", ["exports", "@ember/object", "discourse-common/lib/get-owner"], function (_exports, _object, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const SELECTOR_EDITOR_PREVIEW = "#reply-control .d-editor-preview-wrapper > .d-editor-preview";

  var _default = (_obj = {
    setupComponent(args, component) {
      component.setProperties({
        templatesVisible: false,
        model: (0, _getOwner.getOwner)(this).lookup("controller:composer").model
      });
      this.appEvents.on("discourse-templates:show", this, "show");
      this.appEvents.on("discourse-templates:hide", this, "hide");
    },

    teardownComponent() {
      this.appEvents.off("discourse-templates:show", this, "show");
      this.appEvents.off("discourse-templates:hide", this, "hide");
    },

    shouldRender(args, component) {
      return !component.site.mobileView;
    },

    show() {
      const elemEditorPreview = document.querySelector(SELECTOR_EDITOR_PREVIEW);

      if (elemEditorPreview) {
        elemEditorPreview.style.display = "none";
      }

      this.set("templatesVisible", true);
    },

    hide() {
      const elemEditorPreview = document.querySelector(SELECTOR_EDITOR_PREVIEW);

      if (elemEditorPreview) {
        elemEditorPreview.style.display = "";
      }

      this.set("templatesVisible", false);
    }

  }, (_applyDecoratedDescriptor(_obj, "show", [_object.action], Object.getOwnPropertyDescriptor(_obj, "show"), _obj), _applyDecoratedDescriptor(_obj, "hide", [_object.action], Object.getOwnPropertyDescriptor(_obj, "hide"), _obj)), _obj);

  _exports.default = _default;
});
define("discourse/plugins/discourse-templates/controllers/discourse-templates-modal", ["exports", "@ember/controller", "@ember/object", "discourse-common/lib/get-owner", "discourse/mixins/modal-functionality"], function (_exports, _controller, _object, _getOwner, _modalFunctionality) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _controller.default.extend(_modalFunctionality.default, (_obj = {
    init() {
      this._super(...arguments);

      this.set("model", (0, _getOwner.getOwner)(this).lookup("controller:composer").model);
    },

    hide() {
      this.send("closeModal");
      this.textarea = null;
    }

  }, (_applyDecoratedDescriptor(_obj, "hide", [_object.action], Object.getOwnPropertyDescriptor(_obj, "hide"), _obj)), _obj));

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/template-item"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,\"details\"],[14,0,\"template-item\"],[15,1,[29,[\"template-item-\",[33,0,[\"id\"]]]]],[12],[1,\"\\n  \"],[10,\"summary\"],[14,0,\"template-item-title\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"template-item-title-text\"],[12],[1,[33,0,[\"title\"]]],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"actions\"],[12],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"class\",\"action\",\"icon\"],[\"templates-apply\",[28,[37,2],[[30,0],\"apply\"],null],\"far-clipboard\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"templates-content\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[[33,0,[\"content\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"template\",\"d-button\",\"action\",\"cook-text\"]]","moduleName":"javascripts/discourse/templates/components/template-item","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/templates-filterable-list"] = Ember.HTMLBars.template({"id":null,"block":"[[[6,[39,0],null,[[\"condition\"],[[33,1]]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"templates-filter-bar\"],[12],[1,\"\\n\"],[41,[33,3,[\"tagging_enabled\"]],[[[1,\"      \"],[1,[28,[35,4],null,[[\"availableTags\",\"tagId\",\"onChange\"],[[33,5],[33,6],[28,[37,7],[[30,0],\"changeSelectedTag\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[28,[35,8],null,[[\"class\",\"value\",\"placeholder\"],[\"templates-filter\",[33,9],[28,[37,10],[\"templates.filter_hint\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"templates-list\"],[12],[1,\"\\n\"],[42,[28,[37,12],[[28,[37,12],[[33,13]],null]],null],null,[[[1,\"      \"],[1,[28,[35,14],null,[[\"template\",\"model\",\"onInsertTemplate\"],[[30,1],[33,15],[28,[37,7],[[30,0],\"insertTemplate\"],null]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"r\"],false,[\"conditional-loading-spinner\",\"loadingReplies\",\"if\",\"siteSettings\",\"templates-tag-drop\",\"availableTags\",\"selectedTag\",\"action\",\"text-field\",\"listFilter\",\"i18n\",\"each\",\"-track-array\",\"filteredReplies\",\"template-item\",\"model\"]]","moduleName":"javascripts/discourse/templates/components/templates-filterable-list","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/above-modal-header/discourse-templates-modal-hijacker"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"modal-header hijacked-modal-header\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\",\"@class\",\"@title\",\"@action\"],[\"angle-double-left\",\"btn-flat modal-close close\",\"templates.close_templates\",[28,[37,3],[[30,0],\"hide\"],null]]],null],[1,\"\\n    \"],[10,0],[14,0,\"title\"],[12],[1,\"\\n      \"],[10,\"h3\"],[14,1,\"discourse-modal-title\"],[12],[1,[28,[35,4],[\"templates.insert_template\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"modal-body hijacked-modal-body discourse-templates discourse-templates-modal\"],[12],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"textarea\",\"model\",\"onBeforeInsertTemplate\"],[[33,6],[33,7],[28,[37,3],[[30,0],\"hide\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"active\",\"d-button\",\"action\",\"i18n\",\"templates-filterable-list\",\"textarea\",\"model\"]]","moduleName":"javascripts/discourse/templates/connectors/above-modal-header/discourse-templates-modal-hijacker","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/editor-preview/discourse-templates"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"d-editor-preview\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"class\",\"action\",\"icon\"],[\"modal-close close btn-flat\",[28,[37,3],[[30,0],\"hide\"],null],\"times\"]]]],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"model\",\"onAfterInsertTemplate\"],[[33,5],[28,[37,3],[[30,0],\"hide\"],null]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"templatesVisible\",\"d-button\",\"action\",\"templates-filterable-list\",\"model\"]]","moduleName":"javascripts/discourse/templates/connectors/editor-preview/discourse-templates","isStrictMode":false});
Ember.TEMPLATES["javascripts/modal/discourse-templates-modal"] = Ember.HTMLBars.template({"id":null,"block":"[[[6,[39,0],null,[[\"title\",\"class\"],[\"templates.insert_template\",\"discourse-templates discourse-templates-modal\"]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],null,[[\"textarea\",\"model\",\"onAfterInsertTemplate\"],[[33,2],[33,3],[28,[37,4],[[30,0],\"hide\"],null]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"d-modal-body\",\"templates-filterable-list\",\"textarea\",\"model\",\"action\"]]","moduleName":"javascripts/discourse/templates/modal/discourse-templates-modal","isStrictMode":false});
define("discourse/plugins/discourse-templates/initializers/discourse-templates-add-ui-builder", ["exports", "discourse/lib/plugin-api", "discourse/lib/show-modal"], function (_exports, _pluginApi, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "discourse-templates-add-ui-builder",

    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const currentUser = container.lookup("service:current-user");

      if (siteSettings.discourse_templates_enabled && currentUser !== null && currentUser !== void 0 && currentUser.can_use_templates) {
        (0, _pluginApi.withPluginApi)("0.5", api => {
          patchComposer(api);
          addOptionsMenuItem(api);
          addKeyboardShortcut(api, container);
        });
      }
    }

  };
  _exports.default = _default;

  function patchComposer(api) {
    api.modifyClass("controller:composer", {
      pluginId: "discourse-templates",
      actions: {
        showTemplatesButton() {
          if (this.site.mobileView) {
            (0, _showModal.default)("discourse-templates-modal");
          } else {
            this.appEvents.trigger("composer:show-preview");
            this.appEvents.trigger("discourse-templates:show");
          }
        }

      }
    });
  }

  function addOptionsMenuItem(api) {
    api.addToolbarPopupMenuOptionsCallback(() => {
      return {
        id: "discourse_templates_button",
        icon: "far-clipboard",
        action: "showTemplatesButton",
        label: "templates.insert_template"
      };
    });
  }

  function addKeyboardShortcut(api, container) {
    const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform);
    const modKey = isMac ? "meta" : "ctrl";
    api.addKeyboardShortcut("".concat(modKey, "+shift+i"), event => {
      event.preventDefault();
      const appEvents = container.lookup("service:app-events");
      const activeElement = document.activeElement;
      const composerModel = container.lookup("controller:composer").model;
      const composerElement = document.querySelector(".d-editor");

      if (composerModel && composerElement !== null && composerElement !== void 0 && composerElement.contains(activeElement)) {
        appEvents.trigger("composer:show-preview");
        appEvents.trigger("composer-messages:close");
        appEvents.trigger("discourse-templates:show");
        return;
      }

      if ((activeElement === null || activeElement === void 0 ? void 0 : activeElement.nodeName) === "TEXTAREA") {
        const modal = document.querySelector(".d-modal");

        if (modal !== null && modal !== void 0 && modal.contains(activeElement)) {
          appEvents.trigger("discourse-templates:hijack-modal", {
            textarea: activeElement
          });
        } else {
          const modalController = container.lookup("controller:discourse-templates-modal");
          (0, _showModal.default)("discourse-templates-modal");
          modalController.set("textarea", activeElement);
        }
      }
    }, {
      global: true,
      help: {
        category: "templates",
        name: "templates.insert_template",
        definition: {
          keys1: [modKey, "shift", "I"],
          keysDelimiter: "plus"
        }
      }
    });
  }
});
define("discourse/plugins/discourse-templates/lib/apply-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.insertTemplateIntoComposer = insertTemplateIntoComposer;
  _exports.prepareTemplate = prepareTemplate;

  function prepareTemplate(templateTitle, templateContent, model) {
    // Replace variables with values.
    if (model) {
      const vars = {
        my_username: model.get("user.username"),
        my_name: model.get("user.name"),
        topic_title: model.get("topic.title"),
        topic_url: model.get("topic.url"),
        original_poster_username: model.get("topic.details.created_by.username"),
        original_poster_name: model.get("topic.details.created_by.name"),
        reply_to_username: model.get("post.username"),
        reply_to_name: model.get("post.name"),
        last_poster_username: model.get("topic.last_poster_username"),
        reply_to_or_last_poster_username: model.get("post.username") || model.get("topic.last_poster_username")
      };

      for (let key in vars) {
        if (vars[key]) {
          templateTitle = templateTitle.replace(new RegExp("%{".concat(key, "(,fallback:.[^}]*)?}"), "g"), vars[key]);
          templateContent = templateContent.replace(new RegExp("%{".concat(key, "(,fallback:.[^}]*)?}"), "g"), vars[key]);
        } else {
          templateTitle = templateTitle.replace(new RegExp("%{".concat(key, ",fallback:(.[^}]*)}"), "g"), "$1");
          templateTitle = templateTitle.replace(new RegExp("%{".concat(key, "}"), "g"), "");
          templateContent = templateContent.replace(new RegExp("%{".concat(key, ",fallback:(.[^}]*)}"), "g"), "$1");
          templateContent = templateContent.replace(new RegExp("%{".concat(key, "}"), "g"), "");
        }
      }
    }

    return {
      templateTitle,
      templateContent
    };
  }

  function insertTemplateIntoComposer(context, _ref) {
    let {
      templateTitle,
      templateContent
    } = _ref;
    const model = context.get("model"); // insert the title if blank

    if (model && !model.title) {
      model.set("title", templateTitle);
    } // insert the content of the template in the compose


    context.appEvents.trigger("composer:insert-block", templateContent);
  }
});

